import React from "react";
import styles from "../styles/Main.module.css";
import {
  transmisia,
  clutch,
  exhaust_system,
  cooling_system,
} from "./helper";
import ServiceComponent from "./ServiceComponent";

const OtherServices = () => {
  return (
    <section className={styles.service__block}>
      <div className={styles.service__content}>
        <div className={styles.other_services__banner}>
          <h1 className={styles.service__banner_title}>
            Інші послуги автосервісу
          </h1>
        </div>
        <div className={styles.service__info}>


          <h2 className={styles.service__title}>Трансмісія</h2>

          {/* <p className={styles.service__text}>
            Наша компанія спеціалізується на ремонті трансмісії автомобіля, що
            відповідає за передачу потужності з двигуна на колеса. Трансмісія
            складається з різних компонентів, таких як механічна коробка
            передач, автоматична трансмісія, муфти, шестерні, ланцюги,
            підшипники та інші деталі.
            <br />
            <br />
            Наші висококваліфіковані техніки здійснюють діагностику трансмісії
            для виявлення проблем, таких як знос деталей, пошкодження шестерень,
            неправильна робота муфт або ланцюгів, витік оливи та інші
            несправності. Після діагностики, ми пропонуємо докладний звіт про
            стан трансмісії та рекомендації щодо ремонтних робіт.
            <br />
            <br />
            Ми використовуємо якісні запасні частини, які відповідають
            стандартам виробників, та сучасне обладнання для виконання ремонту
            трансмісії. Наші експерти здійснюють заміну пошкоджених деталей,
            ремонт механізмів та налагодження трансмісії для відновлення її
            оптимальної роботи.
            <br />
            <br />
            Звертайтеся до нас для професійного ремонту трансмісії вашого
            автомобіля. Ми забезпечимо надійну та ефективну роботу трансмісії,
            що дозволить вам насолоджуватися комфортною та безпечною їздою.
          </p> */}
          <ServiceComponent service={transmisia} />

          <h2 className={styles.service__title}>Зчеплення</h2>

          {/* <p className={styles.service__text}>
            Наша компанія надає послуги з ремонту зчеплення, яке відіграє
            важливу роль у передачі потужності з двигуна на трансмісію
            автомобіля. Зчеплення складається з диска зчеплення, пресуючого
            механізму, вижимного підшипника та інших компонентів.
            <br />
            <br />
            Наші висококваліфіковані техніки проводять діагностику зчеплення для
            виявлення можливих проблем, таких як знос диска зчеплення,
            несправність пресуючого механізму або вижимного підшипника,
            неправильна робота зчеплення та інші несправності. Після
            діагностики, ми рекомендуємо необхідний обсяг ремонтних робіт та
            заміну деталей.
            <br />
            <br />
            Ми використовуємо високоякісні запасні частини, що відповідають
            стандартам виробників, та сучасне обладнання для виконання ремонту
            зчеплення. Наші експерти здійснюють заміну пошкоджених деталей,
            налаштовують та тестують зчеплення для його оптимальної роботи.
            <br />
            <br />
            Звертайтеся до нас для професійного ремонту зчеплення вашого
            автомобіля. Ми забезпечимо надійну роботу зчеплення та відновимо
            його ефективність, що дозволить вам насолоджуватися плавною та
            безпроблемною зміною передач при керуванні автомобілем.
          </p> */}
          <ServiceComponent service={clutch} />

          <h2 className={styles.service__title}>Вихлопна система</h2>

          {/* <p className={styles.service__text}>
            Наша компанія спеціалізується на ремонті вихлопної системи
            автомобіля, що відповідає за відведення вихлопних газів з двигуна та
            зменшення шкідливих викидів в атмосферу. Вихлопна система
            складається з вихлопного колектора, глушника, каталізатора (якщо
            присутній), трубопроводів та інших компонентів.
            <br />
            <br />
            Наші кваліфіковані механіки проводять діагностику вихлопної системи
            для виявлення можливих проблем, таких як пошкодження глушника,
            засмічення каталізатора, протікання трубопроводів або інші
            несправності. Після діагностики, ми рекомендуємо необхідні ремонтні
            роботи та заміну деталей для відновлення оптимальної роботи
            вихлопної системи.
            <br />
            <br />
            Ми використовуємо якісні запасні частини та сучасне обладнання для
            виконання ремонту вихлопної системи. Наші експерти здійснюють заміну
            пошкоджених компонентів, ремонт трубопроводів, очищення або заміну
            каталізатора та інші необхідні налаштування.
            <br />
            <br />
            Звертайтеся до нас для професійного ремонту вихлопної системи вашого
            автомобіля. Ми забезпечимо надійну та ефективну роботу вихлопної
            системи, зменшимо викиди шкідливих речовин та забезпечимо вам
            комфортну та екологічно чисту їзду.
          </p> */}
          <ServiceComponent service={exhaust_system} />

          <h2 className={styles.service__title}>Система охолодження</h2>

          {/* <p className={styles.service__text}>
            Наша компанія займається ремонтом системи охолодження автомобіля,
            яка відповідає за підтримання оптимальної температури двигуна та
            запобігання його перегріву. Система охолодження складається з
            радіатора, вентилятора, водяного насоса, термостата, шлангів та
            резервуара з охолоджуючою рідиною.
            <br />
            <br />
            Наші кваліфіковані механіки проводять діагностику системи
            охолодження для виявлення можливих проблем, таких як витік
            охолоджуючої рідини, несправність радіатора, неефективна робота
            вентилятора, проблеми з водяним насосом або інші несправності. Після
            діагностики, ми рекомендуємо необхідні ремонтні роботи та заміну
            деталей для забезпечення надійної роботи системи охолодження.
            <br />
            <br />
            Ми використовуємо якісні запасні частини, що відповідають стандартам
            виробників, та сучасне обладнання для виконання ремонту системи
            охолодження. Наші експерти здійснюють заміну пошкоджених
            компонентів, ремонт радіатора, налагодження вентилятора та інші
            необхідні регулювання.
            <br />
            <br />
            Звертайтеся до нас для професійного ремонту системи охолодження
            вашого автомобіля. Ми забезпечимо ефективне охолодження двигуна,
            запобіжимо перегріву та збережемо його оптимальну роботу. Ваш
            автомобіль буде готовим до подолання будь-яких дорожніх випробувань
            з надійною системою охолодження.
          </p> */}
          <ServiceComponent service={cooling_system} />


        </div>
      </div>
    </section>
  );
};

export default OtherServices;
